<template>
  <label :for="id" :class="['checkbox', { 'checkbox--disabled': disabled }]">
    <slot />
    <input
      type="checkbox"
      v-bind="
        $attrs
        // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
      "
      :checked="value"
      @change="$emit('update', $event.target.checked)"
      v-on="
        $listeners
        // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      "
    />
    <span class="checkbox__box">
      <span class="checkbox__checkmark" v-show="value && !disabled">🤘</span>
    </span>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  // Change the v-model event name to `update` to avoid changing
  // the behavior of the native `input` event.
  // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  model: {
    event: 'update',
  },
})
export default class Checkbox extends Vue {
  @Prop()
  id?: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  value!: boolean;
}
</script>

<style lang="scss" scoped>
$height: 30px;
.checkbox {
  display: block;
  position: relative;
  padding-left: $height + 10px;
  min-height: $height;
  line-height: $height;
  cursor: pointer;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__box {
    position: absolute;
    top: 0;
    left: 0;
    height: $height;
    width: $height;
    background-color: #eee;
    border: 2px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    &:hover {
      border: 2px solid rgb(100, 0, 0);
    }
  }
  &__checkmark {
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
