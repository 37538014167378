<template>
  <router-link v-if="to" :to="to" class="base-button" :class="size" v-on="$listeners">
    {{ label }}
    <slot></slot>
  </router-link>
  <component v-else :is="as" class="base-button" :class="[{ loading }, size]" v-on="$listeners">
    {{ label }}
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: true,
})
export default class BaseButton extends Vue {
  @Prop({
    default: 'button',
  })
  as!: string;

  @Prop()
  to?: string;

  @Prop()
  label!: string;

  @Prop({ default: 'md' })
  size!: 'sm' | 'md';

  @Prop({
    default: false,
  })
  loading!: boolean;
}
</script>

<style lang="scss" scoped>
.base-button {
  background: $color-theme-red;
  outline: none;
  transition: 0.2s;
  color: $color-theme-yellow;
  cursor: pointer;
  border: 2px solid $color-theme-red;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  &:not([disabled]):hover {
    color: $color-theme-red;
    background: $color-theme-yellow;
  }
  &[disabled] {
    border: 2px solid gray;
    background: none;
    color: gray;
    cursor: not-allowed;
  }
  &.loading {
    cursor: not-allowed;
    background: $color-theme-yellow;
    color: $color-theme-red;
    opacity: 0.5;
  }
  &.sm {
    font-size: 1rem;
    padding: 4px 8px;
  }

  &.md {
    padding: 8px 12px;
  }
}
</style>
