<template>
  <div>
    <transition name="shake">
      <ul class="errors" v-if="errors.length > 0">
        <li class="error" v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </transition>
    <v-select
      class="base-select"
      :value="label"
      @input="(ev) => $emit('input', ev)"
      :reduce="(option) => option.key"
      label="label"
      :searchable="searchable"
      :options="options"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import vSelect from 'vue-select';

export interface Option {
  key: string;
  label: string;
}

@Component({
  components: {
    vSelect,
  },
  inheritAttrs: false,
})
export default class BaseSelect extends Vue {
  @Prop({ default: false })
  searchable!: boolean;

  @Prop({ required: true, default: () => [] })
  options!: Option[];

  @Prop()
  value!: string;

  @Prop({ type: Array, default: () => [] })
  public errors!: string[];

  get label(): string {
    const option = this.options.find((a) => a.key === this.value);
    return option ? option.label : '';
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.base-select {
  /* margin-top: 6px;
  margin-bottom: 10px; */
  font-size: 24px !important;
  white-space: nowrap;
  .vs__dropdown-toggle {
    background: $color-input;
    border: 2px solid black !important;
    border-radius: 0;
    border: none;
    padding: 0;
  }
  .vs__selected-options {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
  }
  .vs__search,
  .vs__selected {
    margin-top: 2px;
  }
  .vs__actions {
    padding-top: 0;
  }
}

.errors {
  font-size: 16px;
  color: $color-theme-red;
  .error {
    margin: 4px 0;
  }
}
</style>
