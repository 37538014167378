<template>
  <div id="app">
    <audio-player />
    <transition name="zoom-in" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script lang="ts">
import AudioPlayer from '@/components/media/AudioPlayer.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    AudioPlayer,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
@import 'src/assets/scss/reset.scss';

body {
  background: black;
}
#app {
  height: 100%;
}

body,
input,
button {
  font-family: 'New Rocker', 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif !important;
  font-size: 1.1rem;
  line-height: 1.3;
  -webkit-overflow-scrolling: touch;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

/* HACKS */
body,
html {
  height: 100%;
}

a {
  cursor: pointer;
  color: $color-theme-red;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

/* STANDARD ELEMENTS */
button:focus {
  outline: 0 !important;
}

.bottom-wrapper {
  transition: all 0.2s ease-in-out;
  //  background: rgba(2, 2, 2, 0.13);
  //  box-shadow: 0 -5px 5px 0 rgba(2, 2, 2, 0.13);
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  height: 100%;

  @include mobile {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
