<template>
  <div class="block" id="app">
    <img class="block__image" src="@/assets/img/logo.png" alt="Avatar country" />
    <h1 class="block__title">The City Gates are temporarily closed!</h1>
    <p class="block__paragraph">The King's department of computer wizards are working hard on solving the issues!</p>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class App extends Vue {}
</script>

<style lang="scss" scoped>
.block {
  width: 100%;
  min-height: 100vh;
  padding: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__image {
    margin-bottom: 40px;
    max-width: 100%;
    width: 500px;
  }
  &__title {
    margin-bottom: 16px;
    color: $color-theme-yellow;
  }
  &__paragraph {
    margin-bottom: 16px;
    color: $color-theme-yellow;
  }
}
</style>
