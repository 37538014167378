import gql from 'graphql-tag';
import { apolloClient } from '../apollo/client';
import { WebEnvironment } from '../types/api';

let e: WebEnvironment | undefined;

export const getEnvironment = async (): Promise<WebEnvironment> => {
  if (e) return e;
  const { data, errors } = await apolloClient.query<{
    webEnvironment: WebEnvironment;
  }>({
    query: gql`
      {
        webEnvironment {
          GTM
          SENTRY_DSN
          STRIPE_PUBLISHABLE_KEY
          MUX_ENV_KEY
          PIPELINE_STAGE
          INVITATION_QUERY
        }
      }
    `,
  });
  if (errors) throw errors;
  return (e = data.webEnvironment);
};
