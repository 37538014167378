<template>
  <div id="audio-player" class="audio-player" :class="playing ? 'playing' : ''">
    <div class="audio-player__container">
      <button ref="toggle-button" class="audio-player__toggle" @click="toggleMusic()">
        <pause-svg class="svg pause" v-if="playing" />
        <play-svg class="svg play" v-else />
      </button>
      <div ref="timeline" class="audio-player__timeline" @click="onTimelineClick">
        <div ref="playhead" class="timeline__playhead"></div>
      </div>
    </div>
    <audio ref="audio" :src="$env.radioUrl" type="audio/mp3" @timeupdate="timeUpdate" @canplaythrough="setDuration" />
  </div>
</template>

<script lang="ts">
import PauseSvg from '@/assets/pause.svg';
import PlaySvg from '@/assets/play.svg';
import { EventBus } from '@/utils/event-bus';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    PlaySvg,
    PauseSvg,
  },
})
export default class AudioPlayer extends Vue {
  audioEl!: HTMLAudioElement;
  playheadEl!: HTMLDivElement;
  timelineEl!: HTMLDivElement;
  playing = false;
  duration = 0;

  get timelineWidth(): number {
    return this.timelineEl.offsetWidth - this.playheadEl.offsetWidth;
  }

  async mounted() {
    this.audioEl = this.$refs.audio as HTMLAudioElement;
    this.playheadEl = this.$refs.playhead as HTMLDivElement;
    this.timelineEl = this.$refs.timeline as HTMLDivElement;

    EventBus.$on('toggle-music', this.toggleMusic);
    EventBus.$on('stop-music', this.stopMusic);
    EventBus.$on('start-music', this.startMusic);
    this.audioEl.loop = true;
    this.audioEl.volume = 0.2;
  }

  public toggleMusic() {
    if (this.audioEl.paused) {
      EventBus.$emit('start-music');
    } else {
      EventBus.$emit('stop-music');
    }
  }

  public async stopMusic() {
    if (this.playing) await this.audioEl.pause();
    this.playing = false;
  }

  public async startMusic() {
    if (!this.playing) await this.audioEl.play();
    this.playing = true;
  }

  public onTimelineClick(event: MouseEvent) {
    this.movePlayhead(event);
    this.audioEl.currentTime = this.duration * this.clickPercent(event);
  }

  movePlayhead(event: MouseEvent) {
    const newMargLeft = event.clientX - this.getPosition(this.timelineEl);

    if (newMargLeft >= 0 && newMargLeft <= this.timelineWidth) {
      this.playheadEl.style.marginLeft = newMargLeft + 'px';
    }
    if (newMargLeft < 0) {
      this.playheadEl.style.marginLeft = '0px';
    }
    if (newMargLeft > this.timelineWidth) {
      this.playheadEl.style.marginLeft = this.timelineWidth + 'px';
    }
  }

  // returns click as decimal (.77) of the total timelineWidth
  clickPercent(event: MouseEvent) {
    return (event.clientX - this.getPosition(this.timelineEl)) / this.timelineWidth;
  }

  getPosition(el: HTMLElement) {
    return el.getBoundingClientRect().left;
  }

  timeUpdate() {
    const playPercent = this.audioEl.currentTime / this.duration;
    this.playheadEl.style.marginLeft = this.timelineWidth * playPercent + 'px';
  }

  setDuration() {
    this.duration = this.audioEl.duration;
  }
}
</script>

<style lang="scss" scoped>
.audio-player {
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  z-index: 6;
  padding: 20px;
  display: flex;
  justify-content: center;
  transition: all 1s;
  visibility: hidden;
  pointer-events: none;

  &.playing {
    top: 0;
    visibility: visible;
  }
  &__container {
    width: 400px;
    max-width: 60vw;
    background-color: $color-theme-yellow;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 1px 2px 24px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    pointer-events: all;
  }
  &__toggle {
    height: 20px;
    &.desktop {
      height: 30px;
    }
    width: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    svg {
      margin-left: 4px;
      width: 20px;
      height: 100%;
      fill: black;
      &.pause {
      }
    }
    &:hover {
      svg {
        fill: $color-theme-red;
      }
    }
  }
  &__timeline {
    margin: 0 12px;
    flex: 1;
    background-color: white;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      .timeline__playhead {
        background-color: $color-theme-red;
      }
    }
    .timeline__playhead {
      width: 10px;
      height: 10px;
      &.desktop {
        width: 16px;
        height: 16px;
      }
      border-radius: 3px;
      background-color: $color-theme-red;
    }
  }
}
</style>
