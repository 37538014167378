import Vue from 'vue';
import Router from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import { authGuard, globalGuard, meetAndGreetGuard, publicGuard } from './middleware/guards';

Vue.use(Router);
Vue.use(RouterPrefetch);

export default function (options: { invitationQuery?: string } = {}) {
  const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        redirect: '/public/login',
      },
      {
        path: '/wall',
        name: 'wall',
        component: () => import('./Block.vue'),
      },
      {
        path: '/welcome',
        name: 'welcome',
        component: () => import('./views/Welcome.vue'),
      },
      {
        path: '/reset',
        name: 'reset',
        component: () => import('./views/ResetPassword.vue'),
      },
      {
        path: '/verify',
        name: 'verify',
        redirect: '/public/login',
      },
      {
        path: '/auth',
        redirect: '/auth/panorama',
        component: () => import('./layouts/Auth.vue'),
        beforeEnter: authGuard,
        children: [
          {
            path: 'panorama',
            name: 'panorama',
            component: () => import('./views/CityPanorama.vue'),
          },
          {
            path: 'news',
            name: 'news',
            component: () => import('./views/News.vue'),
          },
          {
            path: 'bank',
            name: 'bank',
            component: () => import('./views/Bank.vue'),
          },
          {
            path: 'sage',
            name: 'sage',
            component: () => import('./views/Sage.vue'),
          },
          {
            path: 'merchant',
            name: 'merchant',
            component: () => import('./views/Shop.vue'),
          },
          {
            path: 'ministry',
            name: 'ministry',
            component: () => import('./views/Ministry.vue'),
          },
          {
            path: 'cinema',
            name: 'cinema',
            component: () => import('./views/Cinema.vue'),
          },
          {
            path: 'studio',
            name: 'studio',
            component: () => import('./views/Studio.vue'),
          },
          {
            path: 'arena',
            name: 'arena',
            component: () => import('./views/Arena.vue'),
          },
          {
            path: 'meet-and-greet',
            name: 'meet-and-greet',
            component: () => import('./views/MeetAndGreet.vue'),
            beforeEnter: meetAndGreetGuard,
          },
        ],
      },
      {
        path: '/public',
        redirect: '/public/login',
        beforeEnter: publicGuard,
        component: () => import('./layouts/Public.vue'),
        children: [
          {
            path: 'register',
            name: 'register',
            component: () => import('./views/Register.vue'),
          },
          {
            path: 'login',
            name: 'login',
            component: () => import('./views/CityGates.vue'),
          },
        ],
      },
    ],
  });
  router.onError((error) => {
    if (/loading chunk.* failed./i.test(error.message && error.message.toLowerCase())) {
      window.location.reload();
    }
  });
  router.beforeEach(globalGuard(options.invitationQuery));
  return router;
}
