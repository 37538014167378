export interface ValidationError {
  property: string;
  messages: string[];
}

export function getLocaleErrorMessageName(e: any) {
  const s = e.graphQLErrors[0].extensions.code;
  return 'errors.filter;';
}

export function errorCode(e: any): string | undefined {
  const graphQLError = e.graphQLErrors && e.graphQLErrors[0];
  if (graphQLError) {
    let s: string | undefined = graphQLError.extensions && graphQLError.extensions.code;
    if (s === 'BAD_USER_INPUT') {
      s = graphQLError.extensions.errors[0].extensions.code || s;
    }
    return s;
  }
  return undefined;
}

export function isRateLimited(e: any) {
  return errorCode(e) === 'RateLimiterError';
}

export function getLocaleErrorMessage(e: any) {
  return '';
}

export function getValidationErrorsFromError(e: any): ValidationError[] | null {
  if (
    e.graphQLErrors &&
    e.graphQLErrors[0] &&
    e.graphQLErrors[0].extensions &&
    e.graphQLErrors[0].extensions.validationErrors &&
    e.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
  ) {
    return e.graphQLErrors[0].extensions.validationErrors.map((a: any) => ({
      property: a.property as string,
      messages: Object.values(a.constraints) as string[],
    }));
  } else {
    return null;
  }
}

export class UnexpectedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UnexpectedError';
  }
}
