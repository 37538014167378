const STATUS_KEY = 'cookies_status';
const TIMESTAMP_KEY = 'cookies_timestamp';
const DOCUMENT_KEY = 'cookies_document';
const ACCEPT_VALUE = 'accepted';
const DECLINE_VALUE = 'declined';

export const declareCookieConsent = (documentId: string, accept: boolean): void => {
  localStorage.setItem(STATUS_KEY, accept ? ACCEPT_VALUE : DECLINE_VALUE);
  localStorage.setItem(TIMESTAMP_KEY, Date.now().toString());
  localStorage.setItem(DOCUMENT_KEY, documentId);
};

export const agentDidDeclareCookieConsent = (): boolean => {
  const timestamp = localStorage.getItem(TIMESTAMP_KEY);
  const status = localStorage.getItem(STATUS_KEY);
  return !!timestamp && !!status;
};

export const agentDidAcceptCookies = (): boolean => {
  const status = localStorage.getItem(STATUS_KEY);
  return agentDidDeclareCookieConsent() && status === ACCEPT_VALUE;
};
