import { render, staticRenderFns } from "./Block.vue?vue&type=template&id=ee6c3222&scoped=true"
import script from "./Block.vue?vue&type=script&lang=ts"
export * from "./Block.vue?vue&type=script&lang=ts"
import style0 from "./Block.vue?vue&type=style&index=0&id=ee6c3222&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee6c3222",
  null
  
)

export default component.exports