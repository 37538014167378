import { apolloClient } from '@/apollo/client';
import { setCitizen } from '@/utils/ai18n';
import { agentDidDeclareCookieConsent } from '@/utils/cookies';
import gql from 'graphql-tag';
import Vue from 'vue';
import { NavigationGuard } from 'vue-router';
import { User, UserRole } from '../types/api';

const getUser = async (): Promise<User | null> => {
  try {
    const { data } = await apolloClient.query<{ viewer?: User }>({
      query: gql`
        query Viewer {
          viewer {
            id
            username
            role
            meetAndGreetToConfirm {
              id
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    });
    return data.viewer || null;
  } catch (e: any) {
    return null;
  }
};

export const authGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const user = await getUser();
  setCitizen(user ? [UserRole.Admin, UserRole.Citizen].includes(user.role) : false);
  if (user) {
    // setLocale(user.locale.replace('_', '-'));
    if (user.meetAndGreetToConfirm && to.name !== 'meet-and-greet') {
      return next({ name: 'meet-and-greet' });
    }
    return next();
  } else {
    return next({ name: 'login', query: { redirect: to.fullPath } });
  }
};

export const meetAndGreetGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const user = await getUser();
  if (user) {
    if (user.meetAndGreetToConfirm) {
      return next();
    }
    return next({ name: 'panorama' });
  } else {
    return next({ name: 'login' });
  }
};

export const publicGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const user = await getUser();
  if (user) {
    return next({ name: 'panorama' });
  } else {
    return next();
  }
};

export const globalGuard: (invitationQuery: string | undefined) => NavigationGuard<Vue> =
  (invitationQuery: string | undefined) => async (to, from, next) => {
    const requiresInvitationQuery = !!invitationQuery && !agentDidDeclareCookieConsent();
    if (requiresInvitationQuery) {
      const queryIsMatching = to.query.invitation === invitationQuery;
      if (!queryIsMatching) {
        if (to.name !== 'wall') {
          return next({
            name: 'wall',
          });
        } else {
          return next();
        }
      }
    }
    if (!agentDidDeclareCookieConsent()) {
      if (to.name !== 'welcome') {
        return next({
          name: 'welcome',
          query: {
            ...to.query,
            redirect: to.fullPath,
          },
        });
      } else {
        return next();
      }
    }
    return next();
  };
