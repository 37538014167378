import { PluginObject } from 'vue';
import { WebEnvironment } from '../types/api';

const EnvPlugin: PluginObject<WebEnvironment> = {
  install(Vue, env) {
    Vue.prototype.$env = env;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $env: WebEnvironment;
  }
}

export default EnvPlugin;
