/*
  AugmentedTranslator: A wrapper for vue-i18n allowing further message
  localization, by citizen/tourist status and randomizing messages.

  You can't at the moment use {vars} in array messages.
*/

import _Vue from 'vue';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import { errorCode } from './errors';

export interface AugmentedI18n {
  citizen: boolean;
  setCitizen: (b: boolean) => void;
}

export type AugmentedTranslatorType = (s: string, o?: object) => string | LocaleMessageObject;
export type AugmentedErrorTranslatorType = (s: string, l: string) => string;

export function AugmentedTranslator(Vue: typeof _Vue, options: VueI18n): void {
  Vue.prototype.$ai18n = {
    citizen: false,
    setCitizen: (b: boolean) => {
      Vue.prototype.$ai18n.citizen = b;
    },
  } as AugmentedI18n;
  // Defining $at
  (Vue.prototype.$at as AugmentedTranslatorType) = (s: string, obj?: any) => {
    // If obj passed in
    const t = options.t(s, obj ? obj : null);
    // Done if string
    if (typeof t === 'string') {
      return t;
    } else {
      let u: any;
      if (obj) {
        // If obj is passed in, we expect it to be of importance. Therefore we instead call t again,
        // propagating object deeper. This is probably slower. If t is an array, we throw away obj.
        // Maybe TODO: Handle case where t is an array?
        u = t instanceof Array ? t : options.t(s + '.' + (Vue.prototype.$ai18n.citizen ? 'citizen' : 'tourist'), obj);
      } else {
        u = t instanceof Array ? t : Vue.prototype.$ai18n.citizen ? t.citizen : t.tourist;
      }
      return u instanceof Array ? u[Math.floor(Math.random() * u.length)] : u;
    }
  };
  // Defining $aterror
  (Vue.prototype.$aterror as AugmentedErrorTranslatorType) = (error: any, location: string): string => {
    const ec = errorCode(error);
    if (ec) {
      const path = ec && 'errors.' + location + '.' + ec;
      return path && options.te(path) ? (options.t(path) as string) : (options.t('errors.default') as string);
    }
    if (error.networkError) {
      return options.t('errors.networkError') as string;
    }
    return options.t('errors.default') as string;
  };
}

export function setCitizen(isCitizen: boolean) {
  _Vue.prototype.$ai18n.setCitizen(isCitizen);
}
